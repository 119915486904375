<template>
  <Dialog
    ref="diglogRef"
    dialog-title="录入核酸结果"
    :is-show-dialog="isShow"
    label-width="86px"
    dialog-width="620px"
    @closeDialog="closeDialog"
    @submitForm="submitFormInfo">
    <div class="arrange_content">
      <div class="arrange_content_top">
        <div class="arrange_content_top_one">
          <span class="arrange_content_top_one_span">{{ staffInfo.name }}</span>
          <span class="arrange_content_top_one_span">{{ staffInfo.sex }}</span>
          <span class="arrange_content_top_one_span">{{ staffInfo.age }}</span>
        </div>
        <div class="arrange_content_top_two">
          <span class="arrange_content_top_two_span">
            <span style="color: #999999;">联系电话：</span>
            {{ staffInfo.linkPhone }}
          </span>
          <span class="arrange_content_top_two_span">
            <span style="color: #999999;">证件号：</span>
            {{ staffInfo.cardNo }}
          </span>
        </div>
      </div>
    </div>

    <el-form-item label="采样时间：">
      <el-date-picker
        style="width: 280px"
        class="top_left_checktime"
        type="datetime"
        v-model="redformhs.collectTime"
        format="yyyy-MM-dd HH:mm:ss"
        value-format="yyyy-MM-dd HH:mm:ss"
        placeholder="选择时间"
      ></el-date-picker>
    </el-form-item>

    <el-form-item label="核酸结果：">
      <el-radio-group style="width:280px;" v-model="redformhs.labResult">
        <el-radio label="阴性">阴性</el-radio>
        <el-radio label="阳性">阳性</el-radio>
      </el-radio-group>
    </el-form-item>

    <el-form-item label="报告时间：">
      <el-date-picker
        style="width: 280px"
        class="top_left_checktime"
        type="datetime"
        v-model="redformhs.reportTime"
        format="yyyy-MM-dd HH:mm:ss"
        value-format="yyyy-MM-dd HH:mm:ss"
        placeholder="选择时间"
      ></el-date-picker>
    </el-form-item>

    <el-form-item label="检测机构：">
      <el-input style="width: 280px;" v-model="redformhs.labOrg" placeholder="请输入"></el-input>
    </el-form-item>
  </Dialog>
</template>

<script>
import Dialog from '@/components/Dialog';
import { saveWorkerCovid } from '../../api/HotelManagement/roomManagement';

export default {
  name: "EnterCheckRecord",
  components: { Dialog },
  data() {
    return {
      isShow: false,
      staffInfo: {},
      redformhs: {
        collectTime: "",
        labResult: "",
        labOrg: "",
        reportTime:""
      }
    }
  },

  methods: {
    show(data) {
      this.isShow = true;
      this.staffInfo = data;
      this.redformhs = {
        collectTime: "",
        labResult: "",
        labOrg: "",
        workerRegId: data.id,
        reportTime:""
      }
    },

    closeDialog() {
      this.isShow = false;
    },

    // 表单验证
    verifyFormInfo() {
      let { collectTime, labResult, labOrg ,reportTime} = this.redformhs;
      if (!collectTime){
        return this.$message.warning("请选择采样时间");
      } else if (!labResult){
        return this.$message.warning("请选择核酸结果");
      }else if(!labOrg){
        return this.$message.warning("请输入检测机构")
      }else if (!reportTime){
        return this.$message.warning("请输报告时间");
      }else{
        return true;
      }
    },

    async submitFormInfo() {
      console.log(this.verifyFormInfo());
      if (this.verifyFormInfo() != true) return;
      console.log('xaiyibu');
      let params = this.redformhs;
      let result = await saveWorkerCovid(params);
      let { code, msg } = result.data;
      if (code == 200) {
        this.$message.success(msg);
        this.$refs.diglogRef.closeDialog();
        this.$parent.getWorkerRegList();
      } else {
        this.$message.error(msg);
      }
    },
  }
}
</script>

<style scoped lang="scss">
@import "../../static/css/enter-dialog.scss";
</style>
