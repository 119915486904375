// 引入工具函数
import { http, httpimg } from '../../api/index'


// 房间管理信息
export const getIsolationInfoApi = params => http.post('/ohealth/api/v1/isolation/reg/getIsolationInfo', params)

// 房间管理信息 - 详情
export const hotelRoomInfoApi = params => http.get('/ohealth/api/v1/isolation/hotelroom/info', params)

// 房间统计
export const getRoomCountInfoApi = params => http.post('/ohealth/api/v1/isolation/hotelroom/getRoomCountInfo', params)


// 房间管理列表
export const hotelroomntist = params => http.get('/ohealth/api/v1/isolation/hotelroom/list', params)

// 批量保存
export const batchSave = params => http.post('/ohealth/api/v1/isolation/hotelroom/batchSave', params)

// 修改状态
export const updateStatus = params => http.put('/ohealth/api/v1/isolation/hotelroom/updateStatus', params)

// 修改房间
export const isolationpointupdate = params => http.put('/ohealth/api/v1/isolation/hotelroom/update', params)


// 工作人员 历次检测记录
export const workerCovidList = params => http.get('/ohealth/api/v1/isolation/workerlistcovid/list', params)

// 工作人员 录入  历次检测记录
export const saveWorkerCovid = params => http.post('/ohealth/api/v1/isolation/workerlistcovid/save', params)

// 工作人员 列表
export const workerRegList = params => http.get('/ohealth/api/v1/isolation/workerreg/list', params)

// 工作人员详情
export const workerRegInfo = params => http.get('/ohealth/api/v1/isolation/workerreg/info', params)

// 工作人员新增
export const saveWorkerReg = params => http.post('/ohealth/api/v1/isolation/workerreg/save', params)

// 工作人员修改
export const updateWorkerReg = params => http.put('/ohealth/api/v1/isolation/workerreg/update', params)

// 工作人员 打印条码
export const printWorkerCode = params => http.get('/ohealth/api/v1/isolation/workerreg/printWorkerCovidBarCode', params)

// 工作人员 导出
export const exportWorkerRegListApi = params => httpimg.post('/ohealth/api/v1/isolation/workerreg/exportWorkerRegList', params)

// 开启关闭
export const changeStatus = params => http.put('/ohealth/api/v1/isolation/workerreg/changeStatus', params)

// 批量打印
export const batchPrintWorkerCovidBarCode = params => http.post('/ohealth/api/v1/isolation/workerreg/batchPrintWorkerCovidBarCode', params)

// 查询房间列表
export const getHotelRoomByStatus = params => http.get('/ohealth/api/v1/isolation/hotelroom/getHotelRoomByStatus', params)

// 批量修改状态
export const batchUpdateStatus = params => http.put('/ohealth/api/v1/isolation/hotelroom/batchUpdateStatus', params)

// 批量模板下载
export const exportTemplate = params => httpimg.post('/ohealth/api/v1/isolation/hotelroom/exportTemplate', params)

// 上传房间批量导入Excel列表
export const uploadHotelRoomExcel = params => http.post('/ohealth/api/v1/isolation/hotelroom/uploadHotelRoomExcel', params)

// 查询酒店预警
export const getHotelWarn = params => http.get('/ohealth/api/v1/isolation/hotelroom/getHotelWarn', params)

// 保留预警
export const saveHotelWarn = params => http.post('/ohealth/api/v1/isolation/hotelroom/saveHotelWarn', params)